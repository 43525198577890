* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

p {
	font-size: 14px;
	color: #2c2e35;
}

h3 {
	font-size: 24px;
	color: #2c2e35;
}

h4 {
	font-size: 20px !important;
	color: #6f7176;
	font-weight: 400 !important;
}

small {
	font-size: 12px;
	display: block;
	color: #2c2e35;
}

body {
	display: flex;
	flex-direction: column;
	font-family: Arial, sans-serif;
}

.input {
	padding: 10px;
	border-radius: 10px;
	height: 30px;
	border: 1px solid #b2b2b2;
	font-size: 14px;
}

#content {
	min-height: 100vh;
	margin: 0;
	display: flex;
	flex-direction: column;
}

#root {
	height: 100%;
}

.page-content {
	flex: 1;
	display: flex;
	flex-direction: column;
}

/* .header */

.header {
	width: 100%;
	background-color: #ebebec;
	color: #fff;
	padding: 5px;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header p {
	font-size: 12px;
	color: #595b61;
}

.header h3 {
	font-size: 16px;
	color: #595b61;
}

.header .d-flex {
	gap: 20px;
}

.header small {
	font-size: 12px;
	color: #595b61;
}

.user-menu {
	position: absolute;
	top: 48px;
	background: #fff;
	text-align: left;
	padding: 0px 10px;
	height: 0px;
	overflow: hidden;
	transition: all 0.5s ease;
}

.user-menu li,
.sair {
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 10px 0px;
}

.user-menu li img {
	max-width: 15px;
}

.idioma img {
	max-width: 20px;
}

.user-menu.active {
	height: 250px;
	z-index: 1000;
}

.user-menu hr {
	border-top: 1px solid #595b61;
	margin: 0px 0px 10px;
}

.user-menu ul {
	list-style: none;
	padding: 0;
	margin: 10px 0px;
}

.user-menu li {
	cursor: pointer;
}

.sair:hover {
	color: #ff2e16;
	text-decoration: none;
}

.sair {
	color: #6f7075;
	text-decoration: none;
}

.user-menu .idioma {
	margin: 20px 0px 40px;
}

.nome-usuario {
	text-align: left;
}

/* SIDEBAR */

#sidebar {
	width: 220px;
	background-color: #272b37;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	overflow: auto;
	transition: width 0.3s;
	padding: 20px;
	overflow: hidden;
}

#sidebar.collapsed {
	width: 60px;
}

#sidebar .logo-div {
	transition: all 1s ease;
	width: 100%;
	overflow: hidden;
}

#sidebar.collapsed .logo-div {
	width: 22px;
	overflow: hidden;
	transition: all 10s ease;
}

#sidebar p {
	font-size: 14px;
	color: #fff;
	height: 20px;
	margin: 0;
	width: 100%;
	transition: all 1s ease;
	overflow: hidden;
}

#sidebar .no-img {
	padding-left: 40px;
}

#sidebar .menu-item {
	display: flex;
	align-items: center;
	gap: 20px;
	text-decoration: none;
}

#sidebar .submenu-item {
	width: 100%;
}

.menu-item {
	cursor: pointer;
	position: relative;
}

.submenu {
	display: none;
	padding-left: 50px;
	transition: max-height 0.3s ease;
	overflow: hidden;
}

.submenu p {
	margin: 5px 0;
}

.submenu ul {
	padding: 0px;
}

.submenu li {
	color: #fff;
	margin: 8px 0px;
	list-style: none;
}

#sidebar .arrow {
	position: absolute;
	right: 10px;
	transition: transform 0.3s ease;
	transition: opacity 0.3s 0.3s ease;
	opacity: 1;
}

#sidebar.collapsed .arrow {
	opacity: 0;
	transition: opacity 0.1s ease;
}

.arrow.open {
	transform: rotate(90deg); /* seta aponta para baixo */
}

#sidebar .relatorio {
	flex-direction: column;
	align-items: start;
}

#sidebar .relatorio div:nth-child(1) {
	display: flex;
	gap: 20px;
}

#sidebar.collapsed p {
	width: 0%;
	height: 20px;
}

#sidebar .menu-item {
	margin: 30px 0;
}

#sidebar.collapsed + #content {
	margin-left: 60px;
}

/* Content */
#content {
	margin-left: 220px;
	transition: margin-left 0.3s;
	flex-grow: 1;
	background-color: #ebebec;
	padding: 0px 10px;
	overflow: hidden;
}

.subtitle-div {
	background-color: #fff;
	padding: 10px;
	display: flex;
	padding-left: 20px;
	gap: 10px;
	align-items: center;
}

.subtitle-div p {
	margin: 0;
}

/* Alerts */

.alerts {
	display: flex;
	gap: 5px;
	margin: 7px 0px;
}

.alerts div {
	width: 100%;
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: 20px;
}

.todos {
	background-color: #acae9d;
}

.inicio {
	background-color: #c3c4c6;
}

.aprovacao {
	background-color: #ffda71;
}

.participantes {
	background-color: #65d0cd;
}

.rejeitados {
	background-color: #ff2e16;
}

/* Filtros */

.filtros {
	display: flex;
	gap: 30px;
	background-color: #fff;
	padding: 30px 20px;
	flex-direction: column;
}

.filtros .radio {
	display: flex;
	align-items: center;
	gap: 30px;
}

.filtros .radio p {
	font-size: 20px;
}

.filtros .radio div {
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 10px;
}

.filtros .inputs {
	display: flex;
	gap: 15px;
}

.filtros .inputs input {
	padding: 10px;
	border-radius: 10px;
	height: 30px;
	border: 1px solid #b2b2b2;
	font-size: 14px;
}

.filtros .inputs input:nth-of-type(1) {
	width: 300px;
}

.filtros .inputs input:nth-of-type(2) {
	width: 150px;
}

.filtros .inputs input:nth-of-type(3) {
	width: 150px;
}

.filtros .inputs input:nth-of-type(4) {
	width: 200px;
}

.filtros .inputs input:nth-of-type(5) {
	width: 200px;
}

.filtros .inputs input:nth-of-type(6) {
	width: 200px;
}

/* TIMELINE */

.timeline {
	background-color: #fff;
	align-items: center;
	min-height: 175px;
	padding: 20px 0px 20px 20px;
	position: relative;
}

.timeline ol,
li {
	margin: 0;
	padding: 0;
	color: #6f7075;
	font-family: verdana;
	font-size: 14px;
}

.timeline ol {
	display: flex;
	width: 92%;
	justify-content: space-between;
	position: relative;
	margin-left: 20px;
}

.timeline ol:after {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background-color: #6f7075;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
}

.timeline li {
	list-style-type: none;
	position: relative;
	cursor: pointer;
	z-index: 2;
}

.timeline li:after {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #6f7075;
	border: none;
	transition: background-color 0.2s ease;
	margin-top: 6px;
}

li.active:after {
	background-color: #46aeab;
	width: 24px;
	height: 24px;
	margin-top: 0px;
}

.timeline span {
	position: absolute;
	top: calc(100% + 20px);
	left: 50%;
	transform: translate(-50%, 0);
	display: inline-block;
	text-align: center;
	text-align: center;
	width: 100px;
	font-size: 12px;
}

.timeline h4 {
}

.timeline .status {
	position: absolute;
	left: 100px;
}

.timeline .ciclo-de-vida {
	margin-left: auto;
	margin-right: auto;
}

/* Tabela */

.tabela {
	background-color: #fff;
	padding: 20px 20px 50px;
	margin-top: 7px;
	/* border: solid 1px #2c2e35; */
}

.tabela img {
	transform: rotate(-90deg) !important;
	transition: all 0.5s ease;
}

.tabela.active img {
	transform: rotate(0deg) !important;
}

.tabela .d-flex {
	align-items: center;
	gap: 20px;
}

.tabela table {
	width: 100%;
	margin-top: 20px;
}

.tabela thead tr:nth-child(odd) {
	background-color: #fff;
}

.tabela tr:nth-child(odd) {
	background-color: #ebebec;
}

.tabela td {
	text-align: center;
	font-size: 14px;
	color: #595b61;
	padding: 10px 0px;
}

.table-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease;
}

.table-content.show {
	max-height: 1000px; /* Valor grande o suficiente para a altura da tabela */
}

.tabela .d-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;
}

.tabela .d-flex div {
	display: flex;
	align-items: center;
	gap: 10px;
}

.form-create .d-flex a,
.tabela .d-flex a,
.botao {
	color: #fff;
	text-decoration: none;
	padding: 5px 15px;
	min-width: 150px;
	background-color: #bbbdbf;
	border-radius: 20px;
	font-size: 14px;
	text-align: center;
	border: none;
}

.form-create .d-flex a.active,
.tabela .d-flex a.active,
.botao.active {
	background-color: #46aeab;
}

.tabela .d-flex a.active {
	background-color: #46aeab;
}

/* Criar Prescricao */

.prescricao-form {
	display: grid;
	grid-template-columns: repeat(
		19,
		minmax(50px, 1fr)
	); /* Máximo de 9 colunas */
	gap: 15px; /* Espaço entre os inputs */
	width: 100%; /* Ocupa toda a largura */
	margin-top: 10px;
}

.span1 {
	grid-column: span 1;
}

.span2 {
	grid-column: span 2;
}

.span3 {
	grid-column: span 3;
}

.span4 {
	grid-column: span 4;
}

.span5 {
	grid-column: span 5;
}

.span6 {
	grid-column: span 6;
}

.span7 {
	grid-column: span 7;
}

.span8 {
	grid-column: span 8;
}

.span15 {
	grid-column: span 15;
}

#criar-ordem-producao.form-create {
	padding: 20px 20px 20px;
}

.form-create {
	background-color: #fff;
	padding: 20px 20px 50px;
	margin-top: 7px;
	/* border: solid 1px #2c2e35; */
}

.form-create img {
	transform: rotate(-90deg) !important;
	transition: all 0.5s ease;
}

.form-create.active img {
	transform: rotate(0deg) !important;
}

.form-create .d-flex {
	align-items: center;
	gap: 20px;
}

.form-create table {
	width: 100%;
	margin-top: 20px;
}

.form-create thead tr:nth-child(odd) {
	background-color: #fff;
}

.form-create tr:nth-child(odd) {
	background-color: #ebebec;
}

.form-create td {
	text-align: center;
	font-size: 14px;
	color: #595b61;
	padding: 10px 0px;
}

.table-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease;
}

.table-content.show {
	max-height: 1000px; /* Valor grande o suficiente para a altura da form-create */
}

.form-create .d-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;
}

.form-create .d-flex div {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* CRIAR ORDEM PRODUCAO */

.aprovacao-form {
	display: grid;

	grid-template-columns: repeat(12, minmax(50px, 1fr));
	gap: 15px; /* Espaço entre os inputs */
	width: 100%; /* Ocupa toda a largura */
	margin-top: 10px;
}

#aprovar_ordem_producao .col-3{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#aprovar_ordem_producao label{
	font-size: 14px;
}

/* Footer */

.footer {
	display: flex;
	padding: 20px;
	justify-content: end;
	align-items: center;
}

@media only screen and (max-width: 991px) {
	.alerts div {
		flex-direction: column;
		text-align: center;
		align-items: center;
	}
}

@media only screen and (max-width: 768px) {
	.alerts {
		flex-wrap: wrap;
		gap: 5px 1%;
	}

	.alerts div {
		width: 49.5%;
	}

	.alerts .rejeitados {
		width: 100%;
	}

	.filtros {
		flex-wrap: wrap;
		gap: 10px 4%;
	}

	.filtros input {
		width: 46%;
	}

	.timeline {
		min-height: 245px;
	}

	.timeline .d-flex {
		flex-direction: column;
	}

	.timeline h4 {
		position: initial !important;
		margin-left: inherit !important;
		margin-right: auto !important;
	}

	.timeline span {
		font-size: 9px;
	}
}
