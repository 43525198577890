* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  font-size: 14px;
  color: #2c2e35;
}

h3 {
  font-size: 24px;
  color: #2c2e35;
}

h4 {
  font-size: 20px !important;
  color: #6f7176;
  font-weight: 400 !important;
}

small {
  font-size: 12px;
  display: block;
  color: #2c2e35;
}

body {
  flex-direction: column;
  font-family: Arial, sans-serif;
}

#login-page {
  background-image: url(../images/bg-login.jpeg);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: right;
}

.login-form-div {
  max-width: 40%;
  width: 800px;
  height: 100%;
  background-color: rgba(38, 38, 47, 0.8);
  position: relative;
  min-width: 500px;
  margin-left: 60px;
}

.login-form-div form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 560px;
}

.login-form-div .col-12 {
  padding: 5px;
}

.login-form-div .col-12 div {
  display: flex;
  flex-direction: column;
  background-color: rgba(194, 193, 197, 0.5);
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 3px 15px 5px;
  border: 1px solid rgba(194, 193, 197, 0.5);
}

.login-form-div .col-12 div:focus-within {
  border: 1px solid #006b87;
  box-shadow: 0 0 8px 1px #006b87;
}

.login-form-div label {
  font-size: 12px;
  color: #a9b1ce;
}

.login-form-div input {
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #fff;
  height: 20px;
  max-width: 100%;
  font-size: 14px;
}


.login-form-div img {
  max-width: 120px;
  display: block;
  margin: 0px auto 50px;
}

.login-form-div a {
  color: #00a4f3;
  text-align: center;
  font-size: 12px;
}

.login-form-div h2 {
  color: #fff;
  font-size: 26px;
}

.login-form-div .entrar {
  font-size: 14px;
  background-color: #50b1ae;
  border-radius: 20px;
  margin: 0;
  height: 41px;
  padding: 10px;
  width: 100%;
  max-width: 220px;
  text-decoration: none;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.login-form-div .voltar {
  font-size: 14px;
  background-color: #4b5363;
  border-radius: 20px;
  margin: 0;
  padding: 10px;
  width: 100%;
  max-width: 220px;
  text-decoration: none;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.login-form-div .entrar:hover {
  background-color: #17516d;
  color: #fff;
}

.login-form-div .suporte {
  color: #fff;
  margin-top: 40px;
  text-align: center;
}

.login-form-div .suporte a {
  color: #00a4f3;
}

.login-form-div a:hover {
  color: #0d6efd;
}

@media only screen and (max-width: 991px) {
  .login-form-div {
    max-width: 100%;
    width: 100%;
    min-width: unset;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .login-form-div form {
    max-width: 100%;
    width: 90%;
    min-width: unset;
  }
  .login-form-div .col-12 div {
    border-radius: 9px;
    padding: 3px 15px;
  }

 

  .login-form-div .entrar {
    margin: 0px auto;
  }

  .login-form-div .voltar {
    margin: 20px auto 10px;
  }
}
